import axios from 'axios'
import { getRequest, postRequest, putRequest, deleteRequest } from './axios'
import { getDeviceType } from './getDeviceType'
import { getRefreshToken, getLanguage } from './storageUtils'

const { REACT_APP_API_URL } = process.env

const userLogin = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/login`, data)

const checkRefreshToken = (data) =>
  axios.post(`${REACT_APP_API_URL}/api/user/refresh-token`, {}, {
    headers: {
      'Content-Type': 'application/json',
      refreshToken: getRefreshToken()
    }
  }
  )

const getHotGames = ({ gameName, limit, pageNo }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/hot-games?gameName=${gameName}&limit=${limit}&pageNo=${pageNo}`)

const getNewGames = ({ gameName, limit, pageNo }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/new-games?gameName=${gameName}&limit=${limit}&pageNo=${pageNo}`)

const getRegistrationFields = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-registration-fields`)

const getRanks = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-ranks?language=${getLanguage()}`)

const getSeasonRanks = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-season-ranks?language=${getLanguage('language')}`)

const userSignUp = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/sign-up`, data)

const checkIsEmailUnique = (email) =>
  getRequest(`${REACT_APP_API_URL}/api/user/check-unique-constraints?email=${email}`)

const checkIsUsernameUnique = (username) =>
  getRequest(`${REACT_APP_API_URL}/api/user/check-unique-constraints?username=${username}`)

const getGameCategory = () =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-casino-category-listing`)

const getGameSubCategory = ({ categoryId }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-tenant-game-sub-category?categoryId=${categoryId}&deviceType=${getDeviceType()}`)

const getCasinoGames = ({ page, pageSize, sortField, sortOrder, category, searchKey, userID, masterCasinoProviderId }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-all-casino-game?page=${page}&pageSize=${pageSize}&sortField=${sortField}&sortOrder=${sortOrder}&category=${category}&searchKey=${searchKey || ''}&userID=${userID}&masterCasinoProviderId=${masterCasinoProviderId || ''}`)

const getCasinoMenuItems = ({ limit, pageNo, casinoMenuId }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-menu-items?limit=${limit}&pageNo=${pageNo}&casinoMenuId=${casinoMenuId}`)
const getLobbyData = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-menus-item`)

const getTenantConfigurations = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-tenant-configurations`)

const getTenantBannerList = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/banner-list`)

const getTenantLeaderBoard = ({ page, pageSize, from, to }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-tenant-leaderboard?page=${page}&from=${from}&to=${to}&pageSize=${pageSize}`)
const getTenantDetails = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-tenant-detail`)

const getAllCountries = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-all-country`)
const getAllCurrencies = () =>
  getRequest(`${REACT_APP_API_URL}/api/user/currency-list`)

const playGame = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/launch-game`, data)

const demoGame = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/demo-game`, data)

const getProviders = ({ categoryId }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-all-provider?categoryId=${categoryId || ''}`)

const getUserDetails = () =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-user-details`)

const updateUserDetails = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/user/update-user-details`, data)

const addFavoriteGame = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/add-favorite-game`, data)

const removeFavoriteGame = (data) =>
  deleteRequest(`${REACT_APP_API_URL}/api/user/remove-favorite-game`, data)

const getFavoriteGame = ({ limit, pageNo }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-favorite-games?limit=${limit}&pageNo=${pageNo}`)

const verifyEmail = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/verify-email`, data)
const verifyUserPassword = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/verify-withdrawl-password`, data)

const resendEmail = (email) =>
  postRequest(`${REACT_APP_API_URL}/api/user/refresh-email-token`, { email })

const getUserBetHistory = ({ limit, pageNo, search, startDate, endDate, actionType }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-casino-transactions?limit=${limit}&pageNo=${pageNo}&search=${search}&startDate=${startDate}&endDate=${endDate}&actionType=${actionType}`)

const uploadProfileImage = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/user/upload-profile-image`, data)

const removeProfileImage = () =>
  putRequest(`${REACT_APP_API_URL}/api/user/remove-profile-image`)

const forgetPassword = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/forget-password`, data)

const resetPassword = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/user/update-password`, data)

const getUserCredential = (type) =>
  getRequest(`${REACT_APP_API_URL}/api/user/init-pay?type=${type}`)

const getWithdrawalHistory = ({ limit, pageNo, status, startDate, endDate, search }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-withdraw-requests?limit=${limit}&pageNo=${pageNo}&status=${status}&startDate=${startDate}&endDate=${endDate}&search=${search}`)

const cancelWithdrawRequest = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/user/cancel-withdraw-request`, data)

const getDocumentsLabel = () =>
  getRequest(`${REACT_APP_API_URL}/api/document/get-document-label`)

const getDocuments = () =>
  getRequest(`${REACT_APP_API_URL}/api/document/get-documents`)

const updateDocuments = ({ data }) =>
  putRequest(`${REACT_APP_API_URL}/api/document/update-user-document`, data)

const addBrowserId = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/insert-unique-key`, data)

const getAllBonus = ({ limit, pageNo, bonusType }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-all-bonus?limit=${limit}&pageNo=${pageNo}&bonusType=${bonusType}`)

const getBonusDetail = ({ bonusId, userBonusId }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-bonus-detail?bonusId=${bonusId}&userBonusId=${userBonusId}`)

const claimBonus = ({ data }) =>
  postRequest(`${REACT_APP_API_URL}/api/user/avail-bonus`, data)

const getUserBonus = ({ limit, pageNo, status, inDeposit }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-user-bonus?limit=${limit}&pageNo=${pageNo}&status=${status}&inDeposit=${inDeposit}`)

const cancelBonus = ({ data }) =>
  deleteRequest(`${REACT_APP_API_URL}/api/user/cancel-bonus`, data)

const getUserTransactions = ({ limit, pageNo, startDate, endDate, transactionType, search }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-user-transactions?limit=${limit}&pageNo=${pageNo}&startDate=${startDate}&endDate=${endDate}&transactionType=${transactionType}&search=${search}`)

const availBonus = ({ data }) =>
  putRequest(`${REACT_APP_API_URL}/api/user/avail-bonus`, data)

const getLoyaltyLevel = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-loyalty-level`)

const setDailyLimit = ({ data }) =>
  postRequest(`${REACT_APP_API_URL}/api/user/set-daily-limit`, data)

const setLossLimit = ({ data }) =>
  postRequest(`${REACT_APP_API_URL}/api/user/set-loss-limit`, data)

const setDepositLimit = ({ data }) =>
  postRequest(`${REACT_APP_API_URL}/api/user/set-deposit-limit`, data)

const setSessionTime = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/set-session-time`, data)

const setDisableUntil = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/set-disable-until`, data)

const getCmsPage = ({ cmsPageId }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-cms-page?cmsPageId=${cmsPageId}`)

const getAllGames = ({ limit, pageNo, category, search, rating, themes, provider }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-all-games?limit=${limit}&pageNo=${pageNo}&category=${category}&search=${search}&rating=${rating}&themes=${themes}&provider=${provider}&deviceType=${getDeviceType()}`)

const getLimitTable = ({ userId }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-limit-table?userId=${userId}`)

const getLanguages = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-languages`)

const getLanguageData = ({ manual }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-language-support-keys?manual=${manual}`)

const getTopGames = ({ limit }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-top-games?limit=${limit}`)

const getTopWinners = ({ limit }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-top-winners?limit=${limit}`)

const getCurrentWinners = ({ limit }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-current-winners?limit=${limit}`)

const logout = () =>
  postRequest(`${REACT_APP_API_URL}/api/user/logout`)

const getPaymentProviders = ({ paymentType, bonusId }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-payment-providers?paymentType=${paymentType}&bonusId=${bonusId}`)

const verifyPayment = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/validate-payment`, data)

const getPaymentStatus = ({ transactionId, sessionId, aggregator, orderId }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/payment-status?transactionId=${transactionId}&sessionId=${sessionId}&aggregator=${aggregator}&orderId=${orderId}`)
const getSumSubAccessToken = () =>
  getRequest(`${REACT_APP_API_URL}/api/document/init-access-token`)

const getAllReviews = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-reviews`)

const getThemes = () =>
  getRequest(`${REACT_APP_API_URL}/api/user/themes`)

const getCashbackBalance = () =>
  getRequest(`${REACT_APP_API_URL}/api/user/cashback-balance`)

const getCashbackTable = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-cashback-bonus`)

const getWalletAmount = () =>
  getRequest(`${REACT_APP_API_URL}/api/user/wallet-amount`)

const getCasinoProvider = ({ category = '', search }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-all-category-providers?category=${category}${search ? `&search=${search}` : ''}`)

const getMyBets = ({ page, pageSize, from, to }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/my-bets?page=${page}&pageSize=${pageSize}&from=${from}&to=${to}`)

const getAllBets = ({ page, pageSize, from, to }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/all-bets?page=${page}&pageSize=${pageSize}&from=${from}&to=${to}`)

const setWalletAction = ({ data }) =>
  postRequest(`${REACT_APP_API_URL}/api/user/wallet-action`, data)
const setVaultAction = ({ data }) =>
  postRequest(`${REACT_APP_API_URL}/api/user/vault-action`, data)

const getWalletTransactions = ({ page, pageSize, action, currency = '', from, to }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/wallet-transaction?page=${page}&pageSize=${pageSize}&action=${action}&currency=${currency}&from=${from}&to=${to}`)

const getPromotionCashTransaction = ({ page, pageSize, action, currency = '', from, to }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/promotion-cash-transaction?page=${page}&pageSize=${pageSize}&action=${action}&from=${from}&to=${to}`)

const getVaultTransactions = ({ page, pageSize, action, from, to }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/vault-transactions?actionType=${action}&limit=${pageSize}&page=${page}&from=${from}&to=${to}`)
const getBonusTransaction = ({
  page,
  pageSize,
  action,
  from,
  to
}) =>
  getRequest(`${REACT_APP_API_URL}/api/user/user-bonus?bonusType=${action}&limit=${pageSize}&page=${page}&from=${from}&to=${to}`)

const getReferredUsersList = ({ page, pageSize }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/invited-users?page=${page}&pageSize=${pageSize}`)

const getTenantCmsFooter = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-cms-footer`)

const readNotification = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/user/read-notification`, data)

const getNotifications = ({ limit, pageNo }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/unread-notification-count?limit=${limit}&pageNo=${pageNo}`)

const getBonusTickets = ({ limit, pageNo }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/user-unique-tickets?limit=${limit}&pageNo=${pageNo}`)

const getVouchers = ({ limit, pageNo, search }) =>
  getRequest(`${REACT_APP_API_URL}/api/voucher/get-voucher?limit=${limit}&pageNo=${pageNo}&search=${search}`)

const getGiftCards = ({ limit, pageNo, search }) =>
  getRequest(`${REACT_APP_API_URL}/api/voucher/get-gift-voucher?limit=${limit}&pageNo=${pageNo}&search=${search}`)

const rollBonus = () =>
  postRequest(`${REACT_APP_API_URL}/api/user/bonus-roll`)

const redeemVoucher = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/voucher/redeem-voucher`, data)

const redeemGiftCard = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/voucher/redeem-gift-voucher`, data)

const forgetPasswordRequest = (username) =>
  putRequest(`${REACT_APP_API_URL}/api/user/request-forget-password`, username)

const getRollingContestList = ({
  limit,
  pageNo
}) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/rolling-competition?limit=${limit}&pageNo=${pageNo}`)

const addUserToRollingContest = (user) =>
  postRequest(`${REACT_APP_API_URL}/api/user/user-competition`, user)

const getRollLeaderBoard = ({
  page,
  pageSize,
  from,
  to
}) =>
  getRequest(`${REACT_APP_API_URL}/api/user/competition-leaderboard?pageNo=${page}&limit=${pageSize}&from=${from}&to=${to}`)

const getEvents = ({
  limit,
  pageNo
}) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/events?limit=${limit}&pageNo=${pageNo}`)

const getBonusWalletProgress = ({ bonusType }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-user-bonus-wallet?bonusType=${bonusType}`)

const activePauseGiftCard = ({ formData }) =>
  postRequest(`${REACT_APP_API_URL}/api/voucher/active-gift-voucher`, formData)

const getGiftCardProgress = ({ voucherId }) =>
  getRequest(`${REACT_APP_API_URL}/api/voucher/voucher-progress-bar?voucherId=${voucherId}`)
const updateUserLanguage = (formData) => putRequest(`${REACT_APP_API_URL}/api/tenant/set-language`, formData)

export {
  updateUserLanguage,
  getGiftCardProgress,
  activePauseGiftCard,
  getBonusWalletProgress,
  getBonusTransaction,
  getEvents,
  getRollLeaderBoard,
  getRollingContestList,
  addUserToRollingContest,
  forgetPasswordRequest,
  userLogin,
  getAllReviews,
  getRegistrationFields,
  userSignUp,
  getCasinoMenuItems,
  getTenantDetails,
  getAllCountries,
  getLobbyData,
  getGameCategory,
  getGameSubCategory,
  getCasinoGames,
  playGame,
  getProviders,
  getUserDetails,
  updateUserDetails,
  demoGame,
  addFavoriteGame,
  removeFavoriteGame,
  getFavoriteGame,
  verifyEmail,
  resendEmail,
  getUserBetHistory,
  uploadProfileImage,
  removeProfileImage,
  forgetPassword,
  resetPassword,
  getUserCredential,
  getWithdrawalHistory,
  cancelWithdrawRequest,
  getDocumentsLabel,
  getDocuments,
  updateDocuments,
  addBrowserId,
  getAllBonus,
  getBonusDetail,
  claimBonus,
  getUserBonus,
  cancelBonus,
  getUserTransactions,
  availBonus,
  getLoyaltyLevel,
  getCmsPage,
  getAllGames,
  setDailyLimit,
  setLossLimit,
  setDepositLimit,
  setSessionTime,
  setDisableUntil,
  getLimitTable,
  getLanguages,
  getLanguageData,
  getTopGames,
  getTopWinners,
  getCurrentWinners,
  checkIsEmailUnique,
  checkIsUsernameUnique,
  logout,
  getPaymentProviders,
  verifyPayment,
  getPaymentStatus,
  getSumSubAccessToken,
  getThemes,
  getCashbackBalance,
  getCashbackTable,
  getWalletAmount,
  getCasinoProvider,
  getMyBets,
  getAllBets,
  getTenantConfigurations,
  getTenantLeaderBoard,
  setWalletAction,
  getAllCurrencies,
  getWalletTransactions,
  getPromotionCashTransaction,
  verifyUserPassword,
  getTenantBannerList,
  getReferredUsersList,
  getTenantCmsFooter,
  setVaultAction,
  getVaultTransactions,
  readNotification,
  getNotifications,
  getBonusTickets,
  getHotGames,
  getNewGames,
  checkRefreshToken,
  rollBonus,
  getRanks,
  getSeasonRanks,
  getVouchers,
  redeemVoucher,
  getGiftCards,
  redeemGiftCard
}
