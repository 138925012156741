import { takeLatest, put, select, call, all } from 'redux-saga/effects'
import { toast } from '../../components/Toast'
// import { languageCodeList } from '../../constants/languageList'
import { resetState } from '../redux-slices'
import { setSelectedLanguagesStart } from '../redux-slices/language'
import { serialize } from 'object-to-formdata'

import {
  getDocuments,
  getDocumentsLabel,
  getUserDetails,
  removeProfileImage,
  updateDocuments,
  updateUserDetails,
  uploadProfileImage,
  getSumSubAccessToken,
  getAllReviews,
  getCashbackBalance,
  getWalletAmount,
  getAllCurrencies,
  getReferredUsersList,
  getNotifications,
  readNotification,
  getBonusTickets,
  rollBonus,
  getRanks,
  getSeasonRanks,
  addUserToRollingContest,
  getBonusWalletProgress,
  updateUserLanguage
} from '../../utils/apiCalls'

import {
  updateUserLanguageStart,
  updateUserLanguageComplete,
  getRanksStart,
  getRanksSuccess,
  getRanksFailure,
  getSeasonRanksStart,
  getSeasonRanksSuccess,
  getSeasonRanksFailure,
  getReferedUsersStart,
  getReferedUsersSuccess,
  getReferedUsersFailure,
  getDocumentsFailure,
  getDocumentsLabelFailure,
  getDocumentsLabelStart,
  getDocumentsLabelSuccess,
  getDocumentsStart,
  getDocumentsSuccess,
  getUserDetailsFailure,
  getUserDetailsStart,
  getUserDetailsSuccess,
  removeProfileImageComplete,
  removeProfileImageStart,
  updateDocumentsComplete,
  updateDocumentsStart,
  uploadProfileImageComplete,
  uploadProfileImageStart,
  userUpdateInfoComplete,
  userUpdateInfoStart,
  getSumSubAccessTokenStart,
  getSumSubAccessTokenSuccess,
  getSumSubAccessTokenFailure,
  getAllReviewFailure,
  getAllReviewSuccess,
  getAllReviewStart,
  getCashbackBalanceFailure,
  getCashbackBalanceSuccess,
  getCashbackBalanceStart,
  getWalletAmountStart,
  getWalletAmountSuccess,
  getWalletAmountFailure,
  getCurrenciesSuccess,
  getCurrenciesFailure,
  getCurrenciesStart,
  getNotificationsStart,
  getNotificationsFailure,
  getNotificationsSuccess,
  updateNotificationStatusStart,
  updateNotificationStatusFailure,
  getBonusTicketsStart,
  getBonusTicketsSuccess,
  getBonusTicketsFailure,
  rollBonusStart,
  rollBonusSuccess,
  rollBonusFailure,
  setKycStatus,
  addUserToRollingContestStart,
  addUserToRollingContestSuccess,
  addUserToRollingContestFailure,
  bonusWalletProgressStart,
  bonusWalletProgressSuccess,
  bonusWalletProgressFailure
  // updateBonusWalletProgress
  // updateBonusWalletProgress
} from '../redux-slices/user'
import { t } from 'i18next'
import OneSignal from 'react-onesignal'
import { BONUSES, KYC_STATUS, initialLang, initialLangCode } from '../../utils/constants'

const getLanguageData = state => state.language.languageData

export default function * userWatcher () {
  yield takeLatest(updateUserLanguageStart.type, updateUserLanguageWorker)
  yield takeLatest(getUserDetailsStart.type, getUserDetailWorker)
  yield takeLatest(bonusWalletProgressStart.type, bonusWalletProgressWorker)
  yield takeLatest(userUpdateInfoStart.type, updateUserInfo)
  yield takeLatest(uploadProfileImageStart.type, uploadProfileImageWorker)
  yield takeLatest(removeProfileImageStart.type, removeProfileImageWorker)
  yield takeLatest(getDocumentsLabelStart.type, getDocumentsLabelWorker)
  yield takeLatest(getDocumentsStart.type, getDocumentsWorker)
  yield takeLatest(updateDocumentsStart.type, updateDocumentsWorker)
  yield takeLatest(getSumSubAccessTokenStart.type, getSumSubAccessTokenWorker)
  yield takeLatest(getAllReviewStart.type, getAllReviewWorker)
  yield takeLatest(getCashbackBalanceStart.type, getCashbackBalanceWorker)
  yield takeLatest(getWalletAmountStart.type, getWalletAmountWorker)
  yield takeLatest(getCurrenciesStart.type, getCurrenciesWorker)
  yield takeLatest(getReferedUsersStart.type, getReferredUsersListWorker)
  yield takeLatest(getNotificationsStart.type, getNotificationsWorker)
  yield takeLatest(updateNotificationStatusStart.type, updateNotificationStatusWorker)
  yield takeLatest(getBonusTicketsStart.type, getBonusTicketsWorker)
  yield takeLatest(rollBonusStart.type, rollBonusWorker)
  yield takeLatest(getRanksStart.type, getRanksWorker)
  yield takeLatest(getSeasonRanksStart.type, getSeasonRanksWorker)
  yield takeLatest(addUserToRollingContestStart.type, addUserToRollingContestWorker)
}
function * updateUserLanguageWorker (action) {
  try {
    yield updateUserLanguage(action.payload)
    yield put(updateUserLanguageComplete())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateUserLanguageComplete())
  }
}
function * addUserToRollingContestWorker (action) {
  try {
    const {
      data,
      handleClose
    } = action.payload
    const res = yield addUserToRollingContest(data)
    if (res.status === 200) {
      if (!res?.data?.data?.success) {
        toast(t('alreadyRegister'), 'success')
      } else {
        toast(t('registeredForRollingContest'), 'success')
      }
      yield put(addUserToRollingContestSuccess(res.data))
      handleClose()
    }
  } catch (e) {
    yield put(addUserToRollingContestFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * bonusWalletProgressWorker (action) {
  try {
    const tempArr = []
    const bonusValues = Object.values(BONUSES)
    Object.values(BONUSES).forEach((value, index) => {
      tempArr.push(call(getBonusWalletProgress, {
        bonusType: value
      }))
    })
    const res = yield all(tempArr)
    const tempData = {}
    bonusValues?.forEach((item, index) => {
      tempData[item] = res[index]?.data?.data
    })
    yield put(bonusWalletProgressSuccess(tempData))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(bonusWalletProgressFailure())
  }
}
function * updateNotificationStatusWorker (action) {
  const { notificationId, updateNotificationStatus } = action.payload
  try {
    const res = yield readNotification({ notificationId })
    if (res.status === 200) {
      yield updateNotificationStatus(notificationId)
    }
  } catch (e) {
    yield put(updateNotificationStatusFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getNotificationsWorker (action) {
  const { pageNo } = action.payload
  const { notifications } = yield select(state => state.user)
  try {
    const { data } = yield getNotifications(action.payload)
    const tempData = {}
    tempData.totalNotification = data?.data?.totalNotification
    tempData.totalUnreadNotification = data?.data?.totalUnreadNotification
    if (pageNo === 1) {
      tempData.data = data?.data?.result
    } else {
      tempData.data = [...notifications?.data, ...data?.data?.result]
    }
    yield put(getNotificationsSuccess(tempData))
  } catch (e) {
    yield put(getNotificationsFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getBonusTicketsWorker (action) {
  const { pageNo } = action.payload
  const { bonusTickets } = yield select(state => state.user)
  try {
    const { data } = yield getBonusTickets(action.payload)
    if (pageNo === 1) {
      yield put(getBonusTicketsSuccess(data?.data))
    } else {
      const tempData = {
        ...data?.data,
        ticketData: {
          ...bonusTickets?.ticketData,
          rows: [...bonusTickets?.ticketData?.rows, ...data?.data?.ticketData?.rows]

        }
      }
      yield put(getBonusTicketsSuccess(tempData))
    }
  } catch (e) {
    yield put(getBonusTicketsFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getUserDetailWorker () {
  try {
    const { selectedLanguageCode } = yield select(
      (state) => state.language
    )
    const { data } = yield getUserDetails()
    yield put(getUserDetailsSuccess(data?.data?.getUser))
    const kycStatus = data?.data?.getUser?.kycStatus
    const seniorKycStatus = data?.data?.getUser?.kycStatusSenior
    if (kycStatus === KYC_STATUS.REJECTED) {
      yield put(setKycStatus(KYC_STATUS.REJECTED))
    } else if (kycStatus === KYC_STATUS.APPROVED) {
      yield put(setKycStatus(KYC_STATUS.APPROVED))
    } else if (seniorKycStatus === KYC_STATUS.APPROVED) {
      yield put(setKycStatus(KYC_STATUS.APPROVED))
    } else if (seniorKycStatus === KYC_STATUS.REJECTED) {
      yield put(setKycStatus(KYC_STATUS.REJECTED))
    } else {
      yield put(setKycStatus(KYC_STATUS.PENDING))
    }

    yield put(updateUserLanguageStart({ userId: data?.data?.getUser?.userId, languageCode: selectedLanguageCode }))
    yield OneSignal?.login(String(data?.data?.getUser?.userId))
    yield OneSignal?.User?.addAlias('User', String(data?.data?.getUser?.username))
    yield OneSignal?.User?.addTag('depCount', '' + data?.data?.getUser?.depositCount)
    yield OneSignal?.User?.addTag('registered', 'Yes')
  } catch (e) {
    yield put(getUserDetailsFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getReferredUsersListWorker (action) {
  const { page } = action.payload
  try {
    const { data } = yield getReferredUsersList({ ...action.payload })
    if (page === 1) {
      yield put(getReferedUsersSuccess(data?.data?.invitedUserList))
    } else {
      const { referredUsers } = yield select(state => state.user)
      const tempData = {
        rows: [...referredUsers.rows, ...data?.data?.invitedUserList?.rows],
        count: data?.data?.invitedUserList?.count
      }
      yield put(getReferedUsersSuccess(tempData))
    }
  } catch (e) {
    yield put(getReferedUsersFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * updateUserInfo (action) {
  try {
    const { initialState, navigate } = action.payload
    const languageData = yield select(getLanguageData)

    yield updateUserDetails(initialState)
    yield put(userUpdateInfoComplete())

    yield toast(`${t('account')} ${t('updatedSuccess')}`, 'success')

    if (initialState?.password) {
      yield put(resetState())
      yield toast(`${languageData?.loginPassword} ${t('updatedSuccess')}`, 'success')
    }

    if (initialState?.preferredLanguage) {
      yield put(setSelectedLanguagesStart({
        selectedLanguage: initialLang,
        selectedLanguageCode: initialLangCode,
        preSelectedLanguageCode: initialLangCode,
        navigate
      }))
    }
    yield put(getUserDetailsStart())
  } catch (e) {
    yield put(userUpdateInfoComplete())
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * uploadProfileImageWorker (action) {
  try {
    const response = yield uploadProfileImage(action.payload)
    if (response.status === 200) {
      yield put(uploadProfileImageComplete())
      yield toast(`${t('profileImageUploaded')}`, 'success')
      yield put(getUserDetailsStart())
    }
  } catch (e) {
    if (e?.response?.data?.errors[0]?.name === 'fileSizeError') {
      yield toast(`${t('fileSizeError')}`, 'error')
    } else {
      yield toast(e?.response?.data?.errors[0]?.description, 'error')
    }
    yield put(uploadProfileImageComplete())
  }
}

function * removeProfileImageWorker () {
  try {
    const res = yield removeProfileImage()
    if (res.status === 200) {
      yield put(removeProfileImageComplete())
      yield toast(`${t('profileImageRemoved')}`, 'success')
      yield put(getUserDetailsStart())
    }
  } catch (e) {
    yield put(removeProfileImageComplete())
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * getDocumentsLabelWorker () {
  try {
    const { data } = yield getDocumentsLabel()
    yield put(getDocumentsLabelSuccess(data))
  } catch (e) {
    yield put(getDocumentsLabelFailure())
  }
}
function * getRanksWorker () {
  try {
    const { data } = yield getRanks()
    yield put(getRanksSuccess(data?.data))
  } catch (e) {
    yield put(getRanksFailure())
  }
}
function * getSeasonRanksWorker () {
  try {
    const { userDetails } = yield select(state => state?.user)
    const rankId = userDetails?.rankId
    const { data } = yield getSeasonRanks()
    let userLevel = 0
    let levelIndex = -1
    if (rankId) {
      levelIndex = data?.data?.rankDetails?.findIndex(item => item?.rankId === rankId)
      userLevel = levelIndex + 1
    }
    yield put(getSeasonRanksSuccess({ ...data?.data, levelIndex, userLevel }))
  } catch (e) {
    yield put(getSeasonRanksFailure())
  }
}

function * getDocumentsWorker () {
  try {
    const { data } = yield getDocuments()

    yield put(getDocumentsSuccess(data.data.userDocument))
  } catch (e) {
    yield put(getDocumentsFailure())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}
function * getCurrenciesWorker () {
  try {
    const { data } = yield getAllCurrencies()

    yield put(getCurrenciesSuccess(data.data.currencies))
  } catch (e) {
    yield put(getCurrenciesFailure())
  }
}

function * updateDocumentsWorker (action) {
  try {
    const { data } = action && action.payload

    yield updateDocuments({ data: serialize(data) })

    yield put(updateDocumentsComplete())

    yield put(getDocumentsStart())

    yield put(getUserDetailsStart())

    yield toast(`${t('docs')} ${t('updatedSuccess')}`, 'success')
  } catch (e) {
    yield put(updateDocumentsComplete())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * getSumSubAccessTokenWorker (action) {
  try {
    const { data } = yield getSumSubAccessToken()

    yield put(getSumSubAccessTokenSuccess(data?.data?.token))
  } catch (e) {
    yield put(getSumSubAccessTokenFailure())

    yield toast(e?.response?.data?.message, 'error')
  }
}

function * getAllReviewWorker () {
  try {
    const { data } = yield getAllReviews()

    yield put(getAllReviewSuccess(data?.data?.reviews))
  } catch (e) {
    yield put(getAllReviewFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getCashbackBalanceWorker () {
  try {
    const { data } = yield getCashbackBalance()

    yield put(getCashbackBalanceSuccess(data?.data?.cashback))
  } catch (e) {
    yield put(getCashbackBalanceFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getWalletAmountWorker () {
  try {
    const { data } = yield getWalletAmount()
    yield put(getWalletAmountSuccess(data?.data?.amount?.amount))
  } catch (e) {
    yield put(getWalletAmountFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * rollBonusWorker (action) {
  try {
    const { handleCloseRollBonusPopup } = action.payload
    const res = yield rollBonus()
    if (res.status === 200) {
      yield put(rollBonusSuccess(res.data?.data))
      yield handleCloseRollBonusPopup()
      yield put(getUserDetailsStart())
    }
    yield toast('Bonus rolled successfully', 'success')
  } catch (e) {
    yield put(rollBonusFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}
