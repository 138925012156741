const KOJSON = {
  translation: {
    claimed: '클레임 완료',
    redTiger: '레드 타이거',
    netEnt: '넷엔터테인먼트',
    bigTimeGaming: '빅 타임 게이밍',
    nolimitCity: '노리밋 시티',
    sorryForInconvenience: '불편을 끼쳐드려 죄송합니다.',
    maintenaceModeOn: '유지 보수 모드가 켜졌습니다.',
    maintenaceContent: '사이트가 곧 이용 가능해질 예정입니다. 양해해 주셔서 감사합니다.',
    wagered: '배팅금액',
    betSlip: '베팅 쿠폰',
    referralCodeInvalid: '유효하지 않은 추천 코드',
    transactions: '내 거래 내역',
    rollBonus: '보너스 전환',
    rollBonusText: '보너스 금액을 현금 금액으로 합산하시겠습니까?',
    liveSupport: '24/7 실시간지원',
    from: '부터',
    to: '까지',
    selectDate: '날짜 선택',
    noVoucherFound: '사용 가능한 바우처가 없습니다',
    leadAmount: '금액',
    rollingContest: '진행중인 롤링대회',
    event: '이벤트',
    bigWin: '명예의 전당',
    referralCodeRequired: '추천 코드 필요',
    home: '집',
    referralCodePlaceholder: '추천 코드를 입력하세요.',
    theme: '테마',
    light: '밝게',
    dark: '어둡게',
    chooseImage: '이미지 선택',
    imageFormatRequired: '이미지는 jpg, png, webp 형식이어야 합니다',
    imageSize5MB: '이미지는 5MB 이내여야 합니다',
    profileImageUploaded: '프로필 이미지가 성공적으로 업로드되었습니다',
    profileImageRemoved: '프로필 이미지가 성공적으로 제거되었습니다',
    allContents: '모든 콘텐츠',
    responsibleGaming: '책임 도박',
    tenanatAreBlocked: '테넌트가 차단되었습니다',
    cashAmount: '현금 금액',
    EMERGENCY: '비상',
    MAINTENANCE: ' 유지 보수',
    Bonus: ' 보너스',
    WALLET: '내 지갑',
    'UNIQUE-NUMBER-BONUS': '고유 번호 보너스',
    LIVE: '생방송',
    LOSE: '지다',
    playing: '놀이',
    noBonusComingSoon: '곧 시작될 예정입니다. 아직 시작되지 않았습니다.',
    noBonusFound: '보너스가 없습니다',
    bonusTickets: '내 보너스티켓',
    couponNumber: '쿠폰 번호',
    totalTickets: '총 티켓 수',
    nonCashAmount: '프로모션 캐시 지갑',
    noNotificationFound: '알림이 없습니다',
    notificationTitle: '알림',
    total: '총계',
    vaultAmount: '금고 금액',
    vault: '내 금고',
    vaultDepositSuccess: '입금 성공',
    vaultWithdrawSuccess: '출금 성공',
    LOST: '잃어버림',
    BET: '베팅',
    WIN: '이김',
    blacklistedError: '블랙리스트에 등록되어 있습니다. 로그인이 허용되지 않습니다.',
    submit: '신청하기',
    Active: '활성화된',
    InActive: '비활성화된',
    date: '시간',
    recommendGames: '게임 추천',
    noProvidersFound: '제공 업체를 찾을 수 없습니다',
    rank: '순위',
    username: '사용자 이름',
    createdAt: '생성 일시',
    status: '상태',
    copy: '복사',
    cancel: '취소',
    confirm: '확인하다',
    copied: '복사됨',
    copiedSuccess: '성공적으로 복사됨',
    referredUsers: '내 추천인 현황',
    referByLink: '링크로 사용자 추천',
    referAUser: '내 추천인 코드',
    noReferralUsers: '추천된 사용자를 찾을 수 없습니다.',
    kycNotComplete: '귀하의 신분증 확인 신청이 대기 중이거나 거부되었습니다. 플랫폼에 액세스하려면 먼저 확인하십시오',
    checkKycStatus: '신분증 확인 상태',
    kycStatusTitle: '신분증 확인 상태',
    verified: '확인됨',
    notVerified: '확인되지 않음',
    APPROVED: '승인됨',
    REJECTED: '거부됨',
    action: '액션',
    voucherName: '바우처 이름',
    validFrom: '유효 시작일',
    validTo: '유효 종료일',
    betTarget: '배팅 대상',
    voucherUseLimit: '바우처 사용 한도',
    redeem: '사용',
    vouchers: '바우처',

    otherLinks: '다른 링크들',
    landingPage: '랜딩 페이지',
    supportLinks: '지원 링크들',
    others: '그 외 게임',
    casinoBets: '실시간 베팅',
    sportBets: '스포츠 베팅',
    insufficientBalance: '잔액이 부족합니다!',
    record: '기록',
    data: '데이터',
    oldPasswordDidNotMatch: '이전 비밀번호가 일치하지 않습니다.',
    loading: '로딩 중...',
    submitting: '제출 중...',
    hide: '숨기기',
    request: '요청',
    clearAll: '모두 지우기',
    noFavouritesGames: '즐겨찾기 목록에 게임이 추가되지 않았습니다.',
    withdrawlPasswordDoesNotMatch: '출금 비밀번호가 일치하지 않습니다.',
    na: '해당 없음',
    ACCEPT: '수락',
    PENDING: '대기 중',
    REJECT: '거부',
    CANCEL: '취소됨',
    withdrawal: '꺼내기 내역',
    prize: '승리금액',
    noDeposit: '입금 없음',
    noWithdrawl: '출금 없음',
    verifyUser: '사용자 확인',
    currencyRequired: '통화가 필요합니다',
    amountRequired: '금액이 필요합니다',
    selectCurrency: '통화 선택',
    currency: '통화',
    requestCreatedSuccess: '요청이 성공적으로 생성되었습니다',
    walletActionSourceAlreadyExists: '보류 중인 요청이 이미 존재합니다',
    min1Required: '최소한 1이 필요합니다',
    positiveNumber: '양수만 허용됩니다',
    logoutContent: '떠나기 전에 저희의 거대한 카지노 및 스포츠 프로모션을 꼭 확인하십시오!',
    success: '성공',
    error: '오류',
    warning: '경고',
    hidden: '숨김',
    show: '보이기',
    amount: '금액',
    enterAmountPlaceholder: '금액 입력',
    requestWithdraw: '확인',
    requestDeposit: '확인',
    language: '언어',
    odds: '오즈',
    decimal: '소수',
    fractional: '분수',
    support: '지원',
    partners: '파트너',
    press: '보도',
    allRightsReserved: '모든 권리 보유',
    acceptTerms: '이용 약관에 동의합니다',
    footerContent: 'Dino은 Medium Rare N.V.가 소유하고 운영하며, 등록 번호는 145353이며 등록 주소는 Willemstad, Curaçao의 Korporaalweg 10입니다. support@dino.com에서 문의하십시오. 결제 대행사는 주소가 Cyprus의 Agioi Omologites에 위치한 LIZANTIA COURT, Office 310의 7-9 Riga Feraiou이며 등록 번호는 HE 410775입니다.',

    // 공통
    game: '게임',
    menu: '메뉴',
    user: '플레이어',
    time: '시간',
    hacksaw: '핵소 게이밍',
    fullScreen: '전체 화면',
    recommended: '추천 게임',
    addToFavourites: '즐겨찾기에 추가',
    removeFromFavourites: '즐겨찾기에서 제거',
    addToFavSuccess: '즐겨찾기에 성공적으로 추가되었습니다',
    removeFromFavSuccess: '즐겨찾기에서 성공적으로 제거되었습니다',
    enableTheatre: '극장 모드 활성화',
    disableTheatre: '극장 모드 비활성화',
    pragmatic: '프래그매틱 플레이',
    evolution: '에볼루션',
    ezugi: '에즈기',
    pgsoft: 'PG 소프트',
    betAmount: '베팅금액',
    payout: '승리금액',
    viewAllProvider: '모든 공급업체 보기',
    loadMore: '더 보기',
    sortBy: '정렬 기준',
    filterBy: '필터 기준',
    atoz: '가 - 나',
    ztoa: '나 - 가',
    all: '전체',
    viewAll: '모두 보기',
    save: '저장',
    areYouSure: '확실합니까',
    of: '의',
    myVouchers: '내 바우처',

    // 헤딩
    unbeatableDeal: '이길 수 없는 거래 내내',
    limitedTimeOffer: '한정 시간 제공',
    biggestSale: '지금까지의 최대 세일!',
    grabYourSaving: '할인 혜택을 잡아보세요',
    newYearDeal: '새해, 새로운 거래!',
    exploreNow: '지금 탐험하기',
    claimNow: '지금 청구하기',
    scratchcard: '스크래치카드',
    slot: '슬롯',
    blackjack: '블랙잭',
    'live games': '라이브 카지노',
    baccarat: '바카라',
    hot: '실시간 인기게임',
    new: '신규 게임',
    roulette: '룰렛',
    unknown: '알 수 없음',

    // 제목 및 링크
    logout: '로그아웃',
    wallet: '내 지갑',
    deposit: '금고에 넣기',
    withdraw: '금고에서 꺼내기',
    setting: '내 정보',
    changePassword: '비밀번호 변경',
    search: '검색',
    login: '로그인',
    casino: '카지노',
    sports: '스포츠',
    eSports: 'E 스포츠',
    noSportsAvailable: '사용 가능한 스포츠가 없습니다',
    favourites: '내 즐겨찾기',
    myBets: '내 배팅 내역',
    mySportsBets: '나의 스포츠 베팅',
    allBets: '총 베팅 리더보드',
    raceLeaderBoard: '명예의 전당',
    raceRollingContestBoard: '토너먼트 랭킹',
    games: '게임',
    searchYourGame: '게임 검색',
    searchAtleast3Char: '검색어는 최소 2자 이상이어야 합니다.',
    providers: '입점 게임사',
    forgotPassword: '비밀번호 잊어버림',
    profile: '내 프로필',
    sportsBook: '스포츠 북',
    comingSoon: '곧 출시 예정',
    goToLobby: '로비로 이동',
    goToCasino: '카지노로 이동',
    goToSports: '스포츠로 가기',
    casinoTitle: '선도적인 온라인 크립토 카지노',
    noCryptoNoProblem: '크립토가 없어도 괜찮아요!',
    buyCrypto: '크립토 구매',
    casinoPara: 'Dino은 공정하고 즐거운 온라인 도박 경험을 제공합니다. 브라우저에서 슬롯, 라이브 카지노, 블랙잭, 바카라, 룰렛 및 수천 개의 클래식 카지노 게임을 플레이하세요. 여기에는 여러분이 좋아하는 Dino Originals도 포함되어 있습니다.',
    sportsPara: 'Dino은 공정하고 즐거운 온라인 도박 경험을 제공합니다. 브라우저에서 슬롯, 라이브 카지노, 블랙잭, 바카라, 룰렛 및 수천 개의 클래식 카지노 게임을 플레이하세요. 여기에는 여러분이 좋아하는 Dino Originals도 포함되어 있습니다.',
    sportsTitle: '최고의 크립토 스포츠 베팅 온라인',
    displaying: '표시 중',
    errorWhileLoadingGame: '게임 로딩 중 오류 발생',
    hotTimeBonus: '핫 타임 보너스',
    compensationBonus: '보상 보너스',
    reocurringBonus: '재입금 보너스',
    totalWalletAmount: '총 지갑 금액',
    bounsWalletAmount: '보너스 지갑 잔액',

    // 폼 라벨
    usernameLabel: '아이디',
    passwordLabel: '비밀번호 설정',
    oldPasswordLabel: '이전 비밀번호 관리',
    newPasswordLabel: '새 비밀번호 관리',
    confirmPasswordLabel: '비밀번호 확인',
    confirmNewPasswordLabel: '비 밀번호 확인',
    idOfUserLabel: '삭제',
    legalNameLabel: '법적 이름 입력',
    nationalityLabel: '국적',
    dateOfBirthLabel: '생년월일',
    selectMonth: '월 선택',
    selectDay: '일 선택',
    selectYear: '년 선택',
    phoneLabel: '전화번호',
    preferredCurrencyLabel: '선호 화폐',
    bankNameLabel: '은행이름',
    accountNumberLabel: '계좌 번호',
    withdrawalPasswordLabel: '출금 비밀번호 설정',
    reEnterwithdrawalPasswordLabel: '출금 비밀번호 확인',

    // 가입 및 계정 정보
    signIn: '로그인',
    signUp: '가입',
    userIdPlaceholder: '삭제 입력',
    passwordRequired: '비밀번호 필수',
    oldPasswordRequired: '이전 비밀번호 관리 필수',
    newPasswordRequired: '새 비밀번호 관리 필수',
    accountFrozen: '귀하의 계정은 다음까지 동결됩니다',
    userLoggedOut: '성공적으로 로그아웃되었습니다',
    loggingIn: '제출 중입니다. 잠시 기다려주세요...',
    selectNationality: '국적 선택',
    selectBankName: '은행 선택',
    selectPreferredCurrency: '선호하는 통화 선택',
    dontHaveAccount: '계정이 없으신가요?',
    registerAnAccount: '계정 등록하기',
    alreadyHaveAnAccount: '이미 계정이 있으신가요?',
    fillAllFields: '가입 전에 모든 필수 필드를 작성하십시오.',
    userNamePlaceholder: '아이디 입력',
    min8Numbers: '최소 8자리 이상이어야 합니다',
    passwordValidationMessage: '비밀번호는 최소 8자 이상이어야 하며, 대문자 하나, 숫자 하나, 특수 문자 하나를 포함해야 합니다. 공백, 점, 쉼표는 허용되지 않습니다',
    withdrawlPasswordValidationMessage: '출금 비밀번호는 최소 8자 이상이어야 하며, 대문자 하나, 숫자 하나, 특수 문자 하나를 포함해야 합니다. 공백, 점, 쉼표는 허용되지 않습니다',
    passwordPlaceholder: '비밀번호 입력',
    confirmPasswordPlaceholder: '비밀번호 확인',
    passwordWithdrawalPlaceholder: '출금 비밀번호 입력',
    oldPasswordPlaceholder: '이전 비밀번호 관리 입력',
    newPasswordPlaceholder: '새 비밀번호 입력',
    confirmNewPasswordPlaceholder: '비밀번호 확인',
    confirmPasswordWithdrawalPlaceholder: '출금 비밀번호 확인',
    legalNamePlaceholder: '법적 이름 입력',
    phonePlaceholder: '전화번호 입력',
    searchPhonePlaceholder: '국가 검색',
    accountNumberPlaceholder: '계좌 번호 입력',
    userIdRequired: '삭제 필수',
    userIdInvalid: '알파벳과 숫자만 허용됩니다',
    confirmPasswordRequired: '비밀번호 확인 필수',
    confirmNewPasswordRequired: '비밀번호 확인 필수',
    passwordWithdrawalRequired: '출금 비밀번호 필수',
    confirmPasswordWithdrawalRequired: '출금 비밀번호 확인 필수',
    legalNameRequired: '법적 이름 필수',
    phoneRequired: '전화번호 필수',
    dateOfBirthRequired: '생년월일 필수',
    userNameRequired: '아이디 필수',
    requiredAccountNumber: '계좌 번호 필수',
    countryRequired: '국가 필수',
    matchPassword: '비밀번호가 일치해야 합니다',
    matchWithdrawalPassword: '출금 비밀번호가 일치해야 합니다',
    onlyAlphabet: '알파벳만 허용됩니다',
    mustBe18: '등록하려면 18세 이상이어야 합니다',
    validDOB: '유효한 생년월일을 입력하세요',
    max20Characters: '최대 20자까지 허용됩니다',
    min3Characters: '최소 3자 이상이어야 합니다',
    min2Characters: '최소 2자 이상이 필요합니다',
    min4Characters: '최소 4자 이상이어야 합니다',
    min5Numbers: '국제 전화 코드를 포함한 최소 5자리 이상이어야 합니다',
    spaceNotAllowed: '공백, 점 및 쉼표는 허용되지 않습니다',
    onlySpaceNotAllowed: '공백만 허용되지 않습니다',
    max100Characters: '최대 100자까지 허용됩니다',
    max50Characters: '최대 50자까지 허용됩니다',
    max30Characters: '최대 30자까지 허용됩니다',
    max16Characters: '최대 16자까지 허용됩니다',
    max16Numbers: '국제 전화 코드를 포함한 최대 16자리까지 허용됩니다',
    preferredLanguageRequired: '선호하는 언어 필수',
    preferredCurrencyRequired: '선호하는 통화 필수',
    bankNameRequired: '은행 선택 필수',
    nationalityRequired: '국적 선택 필수',
    legalName: '알파벳과 공백만 허용됩니다',
    legalNameOneSpace: '단어 사이에는 하나의 공백만 허용됩니다',
    selectPreferredLanguage: '선호하는 언어 선택',
    enterValidPhone: '유효한 전화번호를 입력하세요',
    userNameAllowed: '소문자 알파벳만 허용되며 공백은 허용되지 않습니다',
    VOUCHER: '바우처',

    // 에러
    userNameAlreadyExists: '닉네임이 이미 존재합니다!',
    incorrectCredentials: '제공된 비밀번호로 닉네임이 존재하지 않습니다!',
    UserCountryCodeWithPhoneAlreadyExists: '이 번호로 이미 닉네임이 존재합니다!',
    loginErrorMessage: '계속하려면 로그인하세요',

    // 찾을 수 없음
    pageNotFound: '페이지를 찾을 수 없음',
    suggestToBackHome: '홈으로 돌아가는 것이 좋습니다',
    noGames: '게임을 찾을 수 없음',
    noEvents: '매치를 사용할 수 없습니다',
    oopps: '이런',
    somethingNotWorking: '죄송합니다. 여기서 뭔가 작동하지 않습니다!',
    remainingRedeemptions: '남은 사용 횟수',

    // 에러 메시지
    noDataFound: '데이터를 찾을 수 없음',
    noCategory: '카테고리를 찾을 수 없음',

    // 비밀번호 재설정/잊어버린 비밀번호
    setNewPass: '새로운 비밀번호 설정',

    // 토스트
    loginSuccess: '로그인 성공',
    signupSuccess: '가입 성공',
    updatedSuccess: '업데이트 성공',
    cookieTitle: '쿠키',
    declineButton: '거절',
    acceptButton: '수락',
    cookieDeclinedMessage: '최상의 경험을 위해 쿠키를 수락해야 이 웹사이트를 계속 사용할 수 있습니다. 계속 진행하려면 쿠키를 수락해 주세요.',
    cookieDescription: 'dino.com에 오신 것을 환영합니다! 우리는 사용자 경험을 최적화하기 위해 쿠키를 사용합니다. \'수락\'을 클릭함으로써 이용에 동의합니다. \'거절\'을 클릭하여 거부할 수 있습니다.',
    forgetPasswordRequest: '비밀번호를 잊으 셨나요?',
    forgetPassword: '비밀번호를 잊으 셨나요',
    forgetPasswordRequestSent: '비밀번호 요청을 잊어버렸습니다.',
    itWillbeYourUpdatedWithdrawalPasswordAsWell: '이것이 귀하의 업데이트된 출금 비밀번호로도 사용된다는 점을 유의하십시오.',
    newLevelUnlock: '새로운 레벨 잠금 해제',
    newSeasonUnlock: '새로운 시즌잠금 해제',
    vouchersAndGiftcards: '쿠폰 기프트카드',
    giftCards: '기프트 카드',
    availableRedeemptions: '사용 가능한 교환 횟수',
    noGiftCardFound: '사용 가능한 기프트 카드가 없습니다',
    joinContest: '대회에 참가하다',
    contestName: '토너먼트',
    joincontestContent: '지혜를 걸고, 운명을 승리하라 – 도전할 용기가 있다면 들어오세요!',
    yes: '예',
    no: '아니오',
    pleaseLoginForContest: '대회에 참가하려면 로그인하세요.',
    registeredForRollingContest: '게임에 참가할 준비 완료! 당신은 공식적으로 등록되었습니다!',
    alreadyRegister: '이미 등록되었습니다!!',
    noContestSchedule: '예정된 대회 없음',
    playerName: '플레이어',
    LeadAmount: '리드 금액',

    // ********** SportsBook Translations ********************
    noHotComboAvailable: '현재 핫 콤보를 사용할 수 없습니다',
    pleaseEnterStake: '배팅 금액을 입력하세요',
    single: '싱글',
    combo: '콤보',
    noBetsAdded: '배팅이 추가되지 않았습니다',
    possibleWin: '예상 가능한 승리',
    placeBet: '배팅하기',
    clearBetslip: '배팅 슬립 지우기',
    vs: '대',
    liveEvents: '실시간 이벤트',
    topEvents: '상위 이벤트',
    upcomingEvents: '다가오는 이벤트',
    highlight: '하이라이트',
    schedule: '일정',
    live: '실시간',
    favorite: '내 즐겨찾기',
    myBet: '내 베팅',
    result: '결과',
    prev: '이전',
    next: '다음',
    today: '오늘',
    Tomorrow: '내일',
    favorites: '내 즐겨찾기',
    selectSport: '스포츠 선택',
    selectCountry: '국가 선택',
    selectLeague: '리그 선택',
    fromDate: '시작 날짜',
    toDate: '종료 날짜',
    winAmount: '당첨금액',
    stake: '배팅 금액',
    lostAmount: '손실 금액',
    cashoutAmount: '캐시아웃 금액',
    pending: '보류 중',
    rejected: '거부됨',
    cashoutButton: '캐시아웃',
    betSlipId: '배팅 슬립 ID',
    in_game: '게임 중',
    cashout: '캐시아웃',
    won: '이겼습니다',
    lost: '졌습니다',
    refund: '환불',
    partial_won: '일부 이겼습니다',
    topLeague: '상위 리그',
    noLeagues: '리그를 찾을 수 없습니다',
    alternativeOddType: '대체 홀 타입',
    areYouSureCashout: '캐시아웃 하시겠습니까?',
    alternateStakeButton: '대체 배팅 금액',
    reOfferButton: '베팅 재제안',
    alternativeStakeTitle: '대체 배팅 금액 제안',
    viewAllMarket: 'すべての市場を見るにはここをクリック',
    closed: '閉鎖',
    removeClosedBet: 'ベットスリップから閉じたベットを削除してください！',
    betSetting: '배당 설정',
    any: '모든 배당 변경 수락',
    none: '배당 변경 수락 안 함',
    higher: '더 높은 배당 변경 수락',
    reset: '리셋',
    selectFromDate: '시작 날짜 선택',
    selectToDate: '종료 날짜 선택',
    customBet: '맞춤 베팅',
    calclutedOds: '계산된 배당률',
    removeMainBet: '베팅 슬립에서 메인 베팅을 먼저 제거하세요',
    removeCustomBet: '베팅 슬립에서 맞춤 베팅을 먼저 제거하세요',
    addSameCusomMarket: '동일한 맞춤 마켓 이벤트를 추가하세요',
    enterStake: '배팅 금액을 입력하세요',
    main: '메인',
    custom: '맞춤',
    quickBet: '빠른 베팅',
    quickBetDesc: '빠른 베팅 모드가 켜져 있습니다! 어떤 선택 항목을 클릭하면 즉시 베팅이 이루어집니다. 모든 베팅을 보려면',
    page: '페이지',
    quickBetIsActiveWith: '빠른 베팅이 활성화되었습니다',
    pleaseEnterStakeToActiveBet: '베팅을 활성화하려면 배팅 금액을 입력하세요',
    loginToPlaceBet: '베팅을 하려면 로그인하세요',
    habanero: '하바네로',
    tie: '무승부',
    addAtleasetTwoEvents: '베팅을 하려면 최소 두 개의 맞춤 이벤트를 추가하세요',
    events: '진행중인 이벤트',
    noEventsSchedule: '예정된 이벤트 없음',
    bonus: '보너스',
    reoccuringBonus: '재입금 보너스',
    firstDepositBonus: '첫입금 보너스',
    noBonus: '보너스 없음',
    bonusLapsed: '보너스 소멸',
    bonusRollProgress: '보너스 롤 진행 상황',
    'HOT-TIME-BONUS': '핫 타임 보너스',
    'COMPENSATION-BONUS': '보상 보너스',
    'RE-OCCURING-DEPOSIT-BONUS': '재입금 보너스',
    'FIRST-DEPOSIT-BONUS': '첫입금 보너스',
    'UNIQUE-TICKET-BONUS': '특별 티켓 보너스',
    notActive: '비활성',
    active: '활성',
    clickToViewBonusProgress: '보너스 진행 상황 보려면 클릭',
    depositMoneyGetBonusBenefits: '보너스 혜택을 받으려면 돈을 입금하세요',
    bonusDetails: '내 보너스 진행상황',
    gamble: '주사위',
    poker: '미니게임',
    refresh: '새로고침',
    somethingWentWrong: '문제가 발생했습니다. 다시 로드해주세요',
    season: '시즌',
    unlocked: '잠금 해제됨',
    activate: '활성화',
    pause: '일시 정지',
    activateSuccess: '성공적으로 활성화되었습니다',
    pauseSuccess: '성공적으로 일시 정지되었습니다',
    redeemSuccess: '성공적으로 사용되었습니다',
    betPlaced: '베팅 완료',
    betPlacedSuccess: '베팅이 처리 중입니다. 자세한 정보는 알림을 확인해 주세요.',
    viewProgress: '진행 상황 보기',
    inPlay: '인 플레이',
    dinoCasinoLeaderboard: '다이노 카지노 리더보드',
    welcomeToDinoCasino: '다이노 카지노에 오신 것을 환영합니다!',
    registerYourAccount: '계정을 등록하세요!',
    myLevelProgress: '내 레벨 진행 상황',
    levelStatus: '레벨 상태',
    notStarted: '경기가 아직 시작되지 않았습니다.',
    matchLive: '경기가 라이브 중입니다',
    matchSuspended: '경기가 일시 중단되었습니다',
    ended: '경기가 종료되었습니다',
    matchClosed: '경기가 종료되었습니다',
    marketActive: '시장이 활성화되었습니다',
    marketDeactive: '시장이 비활성화되었습니다',
    marketSuspended: '시장이 일시 중단되었습니다',
    marketSettled: '시장이 정산되었습니다',
    marketCancelled: '시장이 취소되었습니다',
    outcomeDeactive: '결과가 비활성화되었습니다',
    outcomeActive: '결과가 활성화되었습니다',
    outcomeSettled: '결과가 정산되었습니다',
    outcomeSuspended: '결과가 일시 중단되었습니다',
    outcomeCancelled: '결과가 취소되었습니다',
    balance: '잔액',
    remaining: '남은',
    referral: '추천',
    half_won: '절반 승리',
    half_lost: '절반 패배',
    extendednetent: '확장된 네텐트',
    depositWallet: '입금하기',
    withdrawalWallet: '출금하기',
    level: '레벨',
    days: '일',
    hours: '시간',
    minutes: '분',
    seconds: '초',
    dinoCasino: '다이노카지노',
    majorHiddenProgress: '대규모 히든 업데이트 중',
    experienceSoon: '곧 체험 하실 수 있습니다!',
    depositableAmount: '입금 가능 금액',
    withdrawableAmount: '출금 가능 금액',
    cashoutMessage: '현금 인출이 진행 중입니다. 자세한 정보는 알림을 확인하십시오',
    cashoutInprogress: '출금 진행 중',
    favoriteRemoved: '즐겨찾기가 성공적으로 제거되었습니다',
    favoriteAdded: '즐겨찾기가 성공적으로 추가되었습니다',
    maxCustomMarketLimit: '단일 베팅 슬립에서 최대 10개의 맞춤 시장 조합을 선택할 수 있습니다',
    betErrorHotCombo: '이 핫 콤보에 대해 베팅할 수 없습니다. 다른 핫 콤보로 시도하십시오',
    paybackBonus: '페이백 보너스',
    'PAYBACK-BONUS': '페이백-보너스',
    transactionDeposit: '입금 내역',
    transactionWithdrawal: '출금 내역',
    mustBeMultipleOf10000: '금액은 10,000의 배수여야 합니다.',
    min10000Required: '금액은 10,000 이상이어야 합니다.',
    maxAmountEnteredis: '최대 금액은',
    requestErrorMessage: '출금 요청 금액은 현금 지갑 잔액 이하이어야 합니다.',
    topMatch: '최고 경기',
    eventStartIn: '이벤트 시작 시간',
    betNotPlacedPleaseTryAgain: '이 이벤트에 대한 베팅을 할 수 없습니다. 다시 시도해 주세요.',
    topMatchesNotAvailable: '최고 경기가 없습니다.',
    goToEvent: '이벤트로 이동',
    hotCombo: '핫 콤보',
    liveMatchResult: '실시간 경기 결과',
    promotionCash: '프로모션 캐시',
    processing: '처리 중',
    mustBeInteger: '소수점 값은 허용되지 않습니다'
  }
}

export default KOJSON
