import { createSlice } from '@reduxjs/toolkit'
import { KYC_STATUS } from '../../utils/constants'

const userSlice = createSlice({
  name: 'user',
  initialState: {
    loading: false,
    userLoading: false,
    userDetails: null,
    sumsubAccessToken: null,
    reviewList: null,
    cashbackBalance: 0,
    cashbackLoading: false,
    walletLoading: false,
    countryModal: true,
    isSidebarVisible: false,
    mobileHomeSidebarVisible: false,
    pollingUser: false,
    currencies: [],
    referredUsers: {},
    referredUserLoading: false,
    signUpReferralCode: null,
    notifications: {
      count: 0,
      data: []
    },
    bonusTickets: {},
    bonusTicketsLoading: false,
    notificationsLoading: false,
    updateNotificationLoading: false,
    rollBonusLoading: false,
    kycStatus: KYC_STATUS.PENDING,
    ranks: {},
    ranksLoading: false,
    seasonRanks: {},
    seasonRanksLoading: false,
    seasonNotification: [],
    rollingContestLoading: false,
    bonusWalletProgress: {},
    bonusWalletProgressLoading: false
  },
  reducers: {
    updateBonusWalletProgress: (state, { payload }) => ({
      ...state,
      bonusWalletProgress: payload
    }),
    bonusWalletProgressStart: (state) => ({
      ...state,
      bonusWalletProgressLoading: true
    }),
    bonusWalletProgressSuccess: (state, { payload }) => ({
      ...state,
      bonusWalletProgress: payload,
      bonusWalletProgressLoading: false
    }),
    bonusWalletProgressFailure: (state) => ({
      ...state,
      bonusWalletProgressLoading: false,
      bonusWalletProgress: {}
    }),
    updateSeasonNotification: (state, { payload }) => ({
      ...state,
      seasonNotification: payload
    }),
    updateBonusWallet: (state, { payload }) => ({
      ...state,
      userDetails: {
        ...state.userDetails,
        userWallet: {
          ...state.userDetails.userWallet,
          totalAmount: Number(payload?.cashAmount || 0) + Number(payload?.nonCashAmount || 0) + Number(payload?.bonusAmount || 0),
          amount: payload?.cashAmount,
          nonCashAmount: payload?.nonCashAmount,
          bonusWalletAmount: Number(payload?.bonusAmount || 0)

        },
        userBonusWallet: {
          ...state.userDetails.userBonusWallet,
          hotTimeBonus: payload?.hotTimeBonus,
          firstDepositBonus: payload?.firstDepositBonus,
          reoccuringBonus: payload?.reoccuringBonus

        }
      }

    }),
    updateUserWalletAmount: (state, { payload }) => (
      {
        ...state,
        userDetails: {
          ...state.userDetails,
          userWallet: {
            ...state.userDetails.userWallet,
            totalAmount: Number(payload?.cashAmount || 0) + Number(payload?.nonCashAmount || 0) + Number(payload?.bonusAmount || 0),
            amount: payload?.cashAmount,
            nonCashAmount: payload?.nonCashAmount,
            bonusWalletAmount: Number(payload?.bonusAmount || 0)
          },
          userBonusWallet: {
            ...state.userDetails.userBonusWallet,
            hotTimeBonus: payload?.hotTimeBonus,
            firstDepositBonus: payload?.firstDepositBonus,
            reoccuringBonus: payload?.reoccuringBonus,
            paybackBonus: payload?.payBackBonus

          }
        }
      }
    ),
    updateNotification: (state, { payload }) => {
      const tempArr = [{ ...payload, isNotificationRead: false }, ...state.notifications.data]
      return ({
        ...state,
        notifications: {
          ...state.notifications,
          totalUnreadNotification: state.notifications.totalUnreadNotification + 1,
          data: tempArr
        }

      })
    },
    updateUserLevel: (state, { payload }) => {
      const rankId = payload.rankId
      let userLevel = state?.seasonRanks?.userLevel
      let levelIndex = state?.seasonRanks?.levelIndex
      if (rankId) {
        levelIndex = state?.seasonRanks?.rankDetails?.findIndex(item => item?.rankId === rankId)
        userLevel = levelIndex + 1
      }
      return ({
        ...state,
        userDetails: {
          ...state.userDetails,
          rankId: payload.rankId,
          rank: payload.rank
        },
        seasonRanks: {
          ...state.seasonRanks,
          userLevel: userLevel,
          levelIndex: levelIndex
        }
      })
    },
    getBonusTicketsStart: (state) => ({
      ...state,
      bonusTicketsLoading: true
    }),
    setKycStatus: (state, { payload }) => ({
      ...state,
      kycStatus: payload
    }),
    getBonusTicketsSuccess: (state, { payload }) => ({
      ...state,
      bonusTicketsLoading: false,
      bonusTickets: payload
    }),
    getBonusTicketsFailure: (state) => ({
      ...state,
      bonusTicketsLoading: false
    }),
    getNotificationsStart: (state) => ({
      ...state,
      notificationsLoading: true
    }),
    getNotificationsSuccess: (state, { payload }) => ({
      ...state,
      notificationsLoading: false,
      notifications: payload
    }),
    getNotificationsFailure: (state) => ({
      ...state,
      notificationsLoading: false
    }),
    getRanksStart: (state) => ({
      ...state,
      ranksLoading: true
    }),
    getRanksSuccess: (state, { payload }) => ({
      ...state,
      ranksLoading: false,
      ranks: payload
    }),
    getRanksFailure: (state) => ({
      ...state,
      ranksLoading: false
    }),
    getSeasonRanksStart: (state) => ({
      ...state,
      seasonRanksLoading: true
    }),
    getSeasonRanksSuccess: (state, { payload }) => ({
      ...state,
      seasonRanksLoading: false,
      seasonRanks: payload
    }),
    getSeasonRanksFailure: (state) => ({
      ...state,
      seasonRanksLoading: false
    }),
    updateNotificationStatusStart: (state) => ({
      ...state,
      updateNotificationLoading: true
    }),
    updateNotificationStatusSuccess: (state, { payload }) => ({
      ...state,
      notifications: payload,
      updateNotificationLoading: false
    }),
    updateNotificationStatusFailure: (state) => ({
      ...state,
      updateNotificationLoading: false
    }),
    getUserDetailsStart: (state) => ({
      ...state,
      loading: true,
      userLoading: true
    }),
    getUserDetailsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      userLoading: false,
      userDetails: payload
    }),
    getUserDetailsFailure: (state) => ({
      ...state,
      loading: false,
      userLoading: false
    }),
    getReferedUsersStart: (state) => ({
      ...state,
      referredUserLoading: true
    }),
    getReferedUsersSuccess: (state, { payload }) => ({
      ...state,
      referredUserLoading: false,
      referredUsers: payload
    }),
    getReferedUsersFailure: (state) => ({
      ...state,
      referredUserLoading: false
    }),
    userUpdateInfoStart: (state) => ({
      ...state,
      loading: true
    }),
    userUpdateInfoComplete: (state) => ({
      ...state,
      loading: false
    }),
    uploadProfileImageStart: (state) => ({
      ...state,
      loading: true
    }),
    uploadProfileImageComplete: (state) => ({
      ...state,
      loading: false
    }),
    removeProfileImageStart: (state) => ({
      ...state,
      loading: true
    }),
    removeProfileImageComplete: (state) => ({
      ...state,
      loading: false
    }),
    getDocumentsLabelStart: (state) => ({
      ...state,
      loading: true
    }),
    getDocumentsLabelSuccess: (state, { payload }) => ({
      ...state,
      labels: payload,
      loading: false
    }),
    getDocumentsLabelFailure: (state) => ({
      ...state,
      loading: false
    }),
    getDocumentsStart: (state) => ({
      ...state,
      loading: true
    }),
    getDocumentsSuccess: (state, { payload }) => ({
      ...state,
      userDocument: payload,
      loading: false
    }),
    getDocumentsFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateDocumentsStart: (state) => ({
      ...state,
      loading: true
    }),
    updateDocumentsComplete: (state) => ({
      ...state,
      loading: false
    }),
    setDailyLimitSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      userDetails: { ...state?.userDetails, userLimit: payload }
    }),
    setLossLimitSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      userDetails: { ...state?.userDetails, userLimit: payload }
    }),
    setDepositLimitSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      userDetails: { ...state?.userDetails, userLimit: payload }
    }),
    setSessionTimeSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      userDetails: { ...state?.userDetails, userLimit: payload }
    }),
    getLimitTableStart: (state) => ({
      ...state
    }),
    getLimitTableSuccess: (state, { payload }) => ({
      ...state,
      userDetails: {
        ...state?.userDetails,
        userLimit: {
          ...state?.userDetails?.userLimit,
          ...(payload?.dailyBetLimit?.usedLimit !== null && { dailyBetUsedLimit: payload?.dailyBetLimit?.usedLimit }),
          ...(payload?.weeklyBetLimit?.usedLimit !== null && { weeklyBetUsedLimit: payload?.weeklyBetLimit?.usedLimit }),
          ...(payload?.monthlyBetLimit?.usedLimit !== null && { monthlyBetUsedLimit: payload?.monthlyBetLimit?.usedLimit }),
          ...(payload?.dailyLossLimit?.usedLimit !== null && { dailyLossUsedLimit: payload?.dailyLossLimit?.usedLimit }),
          ...(payload?.weeklyLossLimit?.usedLimit !== null && { weeklyLossUsedLimit: payload?.weeklyLossLimit?.usedLimit }),
          ...(payload?.monthlyLossLimit?.usedLimit !== null && { monthlyLossUsedLimit: payload?.monthlyLossLimit?.usedLimit }),
          ...(payload?.dailyDepositLimit?.usedLimit !== null && { dailyDepositUsedLimit: payload?.dailyDepositLimit?.usedLimit }),
          ...(payload?.weeklyDepositLimit?.usedLimit !== null && { weeklyDepositUsedLimit: payload?.weeklyDepositLimit?.usedLimit }),
          ...(payload?.monthlyDepositLimit?.usedLimit !== null && { monthlyDepositUsedLimit: payload?.monthlyDepositLimit?.usedLimit })
        }
      }
    }),
    getLimitTableFailure: (state) => ({
      ...state
    }),
    updateWalletBalance: (state, { payload }) => ({
      ...state,
      userDetails: {
        ...state.userDetails,
        ...(payload?.loyaltyExist && { loyaltyPoints: payload.loyaltyPoints }),
        ...(payload?.loyaltyExist && { level: payload.level }),
        userWallet: { ...state.userDetails?.userWallet, amount: payload.cash, totalAmount: payload.total, nonCashAmount: payload.nonCash }
      }
    }),
    updateDepositError: (state, { payload }) => ({
      ...state,
      userDetails: {
        ...state.userDetails,
        depositError: payload?.depositError,
        errorMsg: payload?.errorMsg
      }
    }),
    getSumSubAccessTokenStart: (state) => ({
      ...state,
      loading: true
    }),
    getSumSubAccessTokenSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      sumsubAccessToken: payload
    }),
    getSumSubAccessTokenFailure: (state) => ({
      ...state,
      loading: false
    }),
    getAllReviewStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllReviewSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      reviewList: payload
    }),
    getAllReviewFailure: (state) => ({
      ...state,
      keysLoading: false
    }),
    getCashbackBalanceStart: (state) => ({
      ...state,
      cashbackLoading: true
    }),
    getCashbackBalanceSuccess: (state, { payload }) => ({
      ...state,
      cashbackLoading: false,
      cashbackBalance: payload
    }),
    getCashbackBalanceFailure: (state) => ({
      ...state,
      cashbackLoading: false
    }),
    getWalletAmountStart: (state) => ({
      ...state,
      walletLoading: true
    }),
    getWalletAmountSuccess: (state, { payload }) => ({
      ...state,
      walletLoading: false,
      userDetails: { ...state.userDetails, userWallet: { ...state.userDetails.userWallet, amount: payload } }
    }),
    getWalletAmountFailure: (state) => ({
      ...state,
      walletLoading: false
    }),
    setCountryModal: (state) => ({
      ...state,
      countryModal: false
    }),
    setSidebarStatusTrue: (state) => ({
      ...state,
      isSidebarVisible: true
    }),
    setSidebarStatusFalse: (state) => ({
      ...state,
      isSidebarVisible: false
    }),
    setMobileSideBarVisible: (state, action) => ({
      ...state,
      mobileHomeSidebarVisible: action.payload
    }),
    getCurrenciesStart: (state, action) => ({
      ...state,
      loading: true
    }),
    getCurrenciesSuccess: (state, action) => ({
      ...state,
      loading: false,
      currencies: action.payload
    }),
    getCurrenciesFailure: (state, action) => ({
      ...state,
      loading: false
    }),
    rollBonusStart: (state, action) => ({
      ...state,
      rollBonusLoading: true
    }),
    rollBonusSuccess: (state, action) => ({
      ...state,
      rollBonusLoading: false
    }),
    rollBonusFailure: (state, action) => ({
      ...state,
      rollBonusLoading: false
    }),
    updateUserLanguageStart: (state, action) => ({
      ...state,
      loading: true
    }),
    updateUserLanguageComplete: (state, action) => ({
      ...state,
      loading: false
    }),
    addUserToRollingContestStart: (state, action) => ({
      ...state,
      rollingContestLoading: true
    }),
    addUserToRollingContestFailure: (state, action) => ({
      ...state,
      rollingContestLoading: false
    }),
    addUserToRollingContestSuccess: (state, action) => ({
      ...state,
      rollingContestLoading: false
    })
  }
})

export default userSlice.reducer

export const {
  updateBonusWallet,
  addUserToRollingContestStart,
  addUserToRollingContestSuccess,
  addUserToRollingContestFailure,
  getUserDetailsStart,
  getUserDetailsSuccess,
  getUserDetailsFailure,
  userUpdateInfoStart,
  userUpdateInfoComplete,
  uploadProfileImageStart,
  uploadProfileImageComplete,
  removeProfileImageStart,
  removeProfileImageComplete,
  getDocumentsLabelStart,
  getDocumentsLabelSuccess,
  getDocumentsLabelFailure,
  getDocumentsStart,
  getDocumentsSuccess,
  getDocumentsFailure,
  updateDocumentsStart,
  updateDocumentsComplete,
  updateWalletBalance,
  updateDepositError,
  setDailyLimitSuccess,
  setLossLimitSuccess,
  setDepositLimitSuccess,
  setSessionTimeSuccess,
  getLimitTableStart,
  getLimitTableSuccess,
  getLimitTableFailure,
  getSumSubAccessTokenStart,
  getSumSubAccessTokenSuccess,
  getSumSubAccessTokenFailure,
  getAllReviewFailure,
  getAllReviewSuccess,
  getAllReviewStart,
  getCashbackBalanceFailure,
  getCashbackBalanceSuccess,
  getCashbackBalanceStart,
  getWalletAmountStart,
  getWalletAmountSuccess,
  getWalletAmountFailure,
  setCountryModal,
  setSidebarStatusTrue,
  setSidebarStatusFalse,
  setMobileSideBarVisible,
  getCurrenciesStart,
  getCurrenciesSuccess,
  getCurrenciesFailure,
  updateUserWalletAmount,
  getReferedUsersStart,
  getReferedUsersSuccess,
  getReferedUsersFailure,
  updateNotificationStatusStart,
  updateNotificationStatusSuccess,
  updateNotificationStatusFailure,
  getNotificationsStart,
  getNotificationsSuccess,
  getNotificationsFailure,
  updateNotification,
  getBonusTicketsStart,
  getBonusTicketsSuccess,
  getBonusTicketsFailure,
  rollBonusStart,
  rollBonusSuccess,
  rollBonusFailure,
  setKycStatus,
  getRanksStart,
  getRanksSuccess,
  getRanksFailure,
  getSeasonRanksStart,
  getSeasonRanksSuccess,
  getSeasonRanksFailure,
  updateUserLevel,
  updateSeasonNotification,
  bonusWalletProgressStart,
  bonusWalletProgressSuccess,
  bonusWalletProgressFailure,
  updateBonusWalletProgress,
  updateUserLanguageStart,
  updateUserLanguageComplete
} = userSlice.actions
